/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_pic_doc': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17 5H7a1 1 0 00-1 1v12a1 1 0 001 1h10a1 1 0 001-1V6a1 1 0 00-1-1zM7 3a3 3 0 00-3 3v12a3 3 0 003 3h10a3 3 0 003-3V6a3 3 0 00-3-3H7z" _fill="#000"/><path pid="1" d="M8 7h8v2H8V7zM8 11h8v2H8v-2zM8 15h5v2H8v-2z" _fill="#000"/>'
  }
})
