/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-more': {
    width: 18,
    height: 4,
    viewBox: '0 0 18 4',
    data: '<circle pid="0" cx="2" cy="2" r="2" _fill="#C4C4C4"/><circle pid="1" cx="9" cy="2" r="2" _fill="#C4C4C4"/><circle pid="2" cx="16" cy="2" r="2" _fill="#C4C4C4"/>'
  }
})
