/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-email-mobile': {
    width: 14,
    height: 11,
    viewBox: '0 0 14 11',
    data: '<path pid="0" d="M12.274.854H1.684C1.064.854.557 1.36.557 1.983V9.51c0 .62.505 1.13 1.129 1.13h10.588c.62 0 1.13-.506 1.13-1.13V1.983c0-.62-.505-1.13-1.13-1.13zm-.156.752L7.003 6.721 1.844 1.606h10.274zM1.308 9.354V2.135L4.934 5.73 1.308 9.354zm.533.533l3.627-3.628L6.74 7.52a.376.376 0 00.531 0l1.24-1.24 3.608 3.608H1.84zm10.809-.533L9.042 5.747l3.608-3.608v7.215z" _fill="#16B853"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M.354 1.983c0-.733.598-1.33 1.33-1.33h10.59c.735 0 1.329.6 1.329 1.33v7.528c0 .735-.6 1.33-1.33 1.33H1.684c-.735 0-1.33-.6-1.33-1.33V1.983zm1.33-.928a.93.93 0 00-.928.928v7.528c0 .51.416.928.928.928h10.59a.93.93 0 00.928-.928V1.983a.93.93 0 00-.929-.928H1.684zm-.328.351h11.246L7.003 7.005 1.356 1.406zm.976.402l4.67 4.63 4.63-4.63h-9.3zm-1.225-.154l4.11 4.075-4.11 4.11V1.655zm11.743 0V9.84L8.758 5.747l4.092-4.092zM1.51 2.618V8.87l3.14-3.14-3.14-3.113zm7.816 3.13L12.45 8.87V2.624L9.325 5.747zm-3.858.23l1.413 1.4c.069.068.18.068.248 0l1.381-1.381 4.093 4.092H1.356l4.111-4.112zm.001.566L2.325 9.687h9.308L8.509 6.563 7.412 7.661a.577.577 0 01-.814.001l-1.13-1.119z" _fill="#16B853"/>'
  }
})
