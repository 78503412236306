/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-action-pause': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 1.7a6.3 6.3 0 100 12.6A6.3 6.3 0 008 1.7zM.3 8a7.7 7.7 0 1115.4 0A7.7 7.7 0 01.3 8zm6.3-2.8a.7.7 0 01.7.7v4.2a.7.7 0 11-1.4 0V5.9a.7.7 0 01.7-.7zm2.8 0a.7.7 0 01.7.7v4.2a.7.7 0 11-1.4 0V5.9a.7.7 0 01.7-.7z" _fill="#CECECE"/>'
  }
})
