/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-card': {
    width: 18,
    height: 13,
    viewBox: '0 0 18 13',
    data: '<path pid="0" d="M3.915 10.85c-1.015 0-1.838-.881-1.838-1.967S2.9 6.916 3.915 6.916c1.016 0 1.839.88 1.839 1.967-.001 1.085-.824 1.965-1.839 1.966zm0-3.02c-.543 0-.984.471-.984 1.053 0 .581.44 1.053.984 1.053s.985-.472.985-1.053c0-.582-.441-1.053-.985-1.053z" _fill="#CECECE"/><path pid="1" d="M5.738 10.85c-1.015 0-1.838-.881-1.838-1.967s.823-1.967 1.838-1.967c1.016 0 1.839.88 1.839 1.967-.001 1.085-.824 1.965-1.839 1.966zm0-3.02c-.543 0-.984.471-.984 1.053 0 .581.44 1.053.984 1.053s.985-.472.985-1.053c0-.582-.441-1.053-.985-1.053zM16.655 3.343H.427C.191 3.343 0 3.14 0 2.887c0-.253.191-.457.427-.457h16.228c.236 0 .427.204.427.457 0 .252-.191.456-.427.456z" _fill="#CECECE"/><path pid="2" d="M17.082 2.284c0-1.261-.956-2.284-2.135-2.284H2.135C.956 0 0 1.023 0 2.284v8.268c0 1.262.956 2.284 2.135 2.284h12.812c1.179 0 2.135-1.022 2.135-2.284V2.284zM2.135.914h12.812c.706 0 1.28.635 1.28 1.39v2.63H.855v-2.63c0-.755.575-1.39 1.281-1.39zm12.812 11.054H2.135c-.706 0-1.28-.635-1.28-1.39v-4.73h15.373v4.73c0 .755-.575 1.39-1.281 1.39z" _fill="#CECECE"/>'
  }
})
