/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-delete': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M4 0h12a4 4 0 014 4v12a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4zm0 2a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H4zm7.414 8l2.829 2.828a1 1 0 11-1.415 1.415L10 11.414l-2.828 2.829a1 1 0 01-1.415-1.415L8.586 10 5.757 7.172a1 1 0 111.415-1.415L10 8.586l2.828-2.829a1 1 0 111.415 1.415L11.414 10z" _fill="#FF2626"/>'
  }
})
