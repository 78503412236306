/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-smiley': {
    width: 68,
    height: 68,
    viewBox: '0 0 68 68',
    data: '<path pid="0" d="M34.2.4A33.8 33.8 0 1068 34.197C68 15.532 52.864.4 34.2.4zm0 60.555a26.757 26.757 0 110-53.513 26.758 26.758 0 110 53.513zM25.401 33.32c2.916 0 5.278-2.757 5.278-6.162 0-3.404-2.366-6.161-5.281-6.161-2.915 0-5.281 2.757-5.281 6.161 0 3.405 2.366 6.162 5.284 6.162zm17.601 0c2.919 0 5.281-2.757 5.281-6.162 0-3.404-2.366-6.161-5.28-6.161-2.916 0-5.282 2.76-5.282 6.161s2.366 6.162 5.281 6.162zm6.482 5.584a2.662 2.662 0 00-3.55 1.127c-.119.232-3.059 5.608-11.73 5.608-8.63 0-11.588-5.327-11.735-5.605a2.643 2.643 0 00-4.715 2.387c.176.349 4.394 8.5 16.45 8.5 12.058 0 16.27-8.155 16.442-8.503a2.623 2.623 0 00-1.162-3.514z" _fill="#000"/>'
  }
})
