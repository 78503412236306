/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-action-see': {
    width: 19,
    height: 12,
    viewBox: '0 0 19 12',
    data: '<path pid="0" d="M9.5 0C5.87 0 2.578 1.986.149 5.212a.752.752 0 000 .898c2.429 3.23 5.72 5.216 9.351 5.216 3.63 0 6.922-1.986 9.351-5.212a.752.752 0 000-.898C16.422 1.986 13.131 0 9.5 0zm.26 9.65a3.999 3.999 0 01-4.248-4.248A4.005 4.005 0 019.24 1.675a3.999 3.999 0 014.248 4.248A4.018 4.018 0 019.76 9.651zm-.12-1.842a2.148 2.148 0 01-2.285-2.285 2.16 2.16 0 012.009-2.01A2.148 2.148 0 0111.649 5.8a2.16 2.16 0 01-2.01 2.01z" _fill="#CECECE"/>'
  }
})
