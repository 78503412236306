/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-accueil-ads': {
    width: 17,
    height: 18,
    viewBox: '0 0 17 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.635 1.81a.216.216 0 00-.27 0L1.827 7.009a.221.221 0 00-.083.173v8.835c0 .122.097.22.218.22h13.077a.22.22 0 00.217-.22V7.18a.221.221 0 00-.083-.173L8.635 1.81zM7.288.425a1.944 1.944 0 012.424 0l6.538 5.197c.474.376.75.951.75 1.56v8.835A1.973 1.973 0 0115.039 18H1.962A1.973 1.973 0 010 16.016V7.18c0-.609.276-1.184.75-1.56L7.288.424zM4.904 9.401c0-1.095.878-1.984 1.961-1.984h3.27c1.083 0 1.961.889 1.961 1.984v3.308a1.973 1.973 0 01-1.961 1.984h-3.27a1.973 1.973 0 01-1.961-1.984V9.4zm1.961-.22a.22.22 0 00-.218.22v3.308c0 .121.098.22.218.22h3.27a.22.22 0 00.218-.22V9.4a.22.22 0 00-.218-.22h-3.27z" _fill="#474748"/>'
  }
})
