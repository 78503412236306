/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-home': {
    width: 15,
    height: 17,
    viewBox: '0 0 15 17',
    data: '<path pid="0" d="M14.094 4.994L9.119.634a2.487 2.487 0 00-3.316 0L.83 4.993A2.487 2.487 0 000 6.868v7.245A2.487 2.487 0 002.487 16.6h9.948a2.487 2.487 0 002.487-2.487V6.86a2.486 2.486 0 00-.829-1.865zm-4.975 9.948H5.803v-4.145a.83.83 0 01.83-.829H8.29a.829.829 0 01.83.83v4.144zm4.145-.829a.829.829 0 01-.829.83h-1.658v-4.146A2.487 2.487 0 008.29 8.31H6.632a2.487 2.487 0 00-2.487 2.487v4.145H2.487a.829.829 0 01-.829-.829V6.86a.829.829 0 01.282-.621l4.974-4.353a.829.829 0 011.094 0l4.975 4.353a.83.83 0 01.281.621v7.254z" _fill="#474748"/>'
  }
})
