/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-select-calendar': {
    width: 12,
    height: 13,
    viewBox: '0 0 12 13',
    data: '<path pid="0" d="M3.734 7.313H2.725a.305.305 0 01-.303-.305V5.992c0-.167.137-.304.303-.304h1.01c.166 0 .302.137.302.304v1.016a.305.305 0 01-.303.304zm2.726-.305V5.992a.305.305 0 00-.303-.304h-1.01a.305.305 0 00-.302.304v1.016c0 .167.136.304.303.304h1.009a.305.305 0 00.303-.304zm2.422 0V5.992a.305.305 0 00-.303-.304H7.57a.305.305 0 00-.302.304v1.016c0 .167.136.304.303.304h1.01a.305.305 0 00.302-.304zM6.46 9.445V8.43a.305.305 0 00-.303-.305h-1.01a.305.305 0 00-.302.305v1.015c0 .168.136.305.303.305h1.009a.305.305 0 00.303-.305zm-2.423 0V8.43a.305.305 0 00-.303-.305H2.725a.305.305 0 00-.303.305v1.015c0 .168.137.305.303.305h1.01a.305.305 0 00.302-.305zm4.845 0V8.43a.305.305 0 00-.303-.305H7.57a.305.305 0 00-.302.305v1.015c0 .168.136.305.303.305h1.01a.305.305 0 00.302-.305zm2.422-6.601v8.937c0 .673-.542 1.219-1.21 1.219H1.21A1.215 1.215 0 010 11.781V2.844c0-.673.543-1.219 1.211-1.219h1.211V.305c0-.168.137-.305.303-.305h1.01c.166 0 .302.137.302.305v1.32h3.23V.305c0-.168.136-.305.303-.305h1.01c.166 0 .302.137.302.305v1.32h1.211c.669 0 1.211.546 1.211 1.219zm-1.21 8.785V4.063H1.21v7.566c0 .084.068.152.152.152h8.579a.152.152 0 00.151-.152z" _fill="#474748"/>'
  }
})
