/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sms': {
    width: 25,
    height: 21,
    viewBox: '0 0 25 21',
    data: '<path pid="0" d="M1.216 16.502l6.648-6.936-5.103-7.368a2.31 2.31 0 00-.467 1.258L1 15.208c-.036.503.036.934.216 1.294zM23.533.977L13.9 9.925l7.583 10.242c.431-.36.719-.898.79-1.545L24 2.594c.072-.647-.144-1.258-.467-1.617z" _fill="#B0BDC6"/><path pid="1" d="M10.236 13.16L7.864 9.565 1.216 16.5c.143.324.395.54.682.611l18.257 3.45c.503.108.97-.071 1.33-.395L13.901 9.925l-3.666 3.234z" _fill="#CAD5DD"/><path pid="2" d="M22.31.437L3.552 1.695c-.287.036-.575.216-.79.503L7.9 9.566l2.372 3.593 3.665-3.234L23.533.977c-.324-.36-.755-.575-1.222-.54z" _fill="#DFE9EF"/>'
  }
})
