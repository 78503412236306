/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-edit-profile': {
    width: 23,
    height: 23,
    viewBox: '0 0 23 23',
    data: '<path pid="0" d="M23 11.5C23 17.851 17.851 23 11.5 23S0 17.851 0 11.5 5.149 0 11.5 0 23 5.149 23 11.5z" _fill="#16B853"/><path pid="1" d="M15.807 5l2.202 2.202L16.33 8.88l-2.202-2.202L15.807 5zM7 13.798V16h2.202l6.09-6.082-2.201-2.202L7 13.798z" _fill="#fff"/>'
  }
})
