/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-phone': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.193.242a.826.826 0 011.168 0l3.305 3.304a.826.826 0 010 1.169L15.36 8.019a.826.826 0 01-1.168-1.168l2.72-2.72-2.72-2.72a.826.826 0 010-1.169z" _fill="#CECECE"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M10.646 4.13c0-.456.37-.826.827-.826h6.608a.826.826 0 010 1.653h-6.608a.826.826 0 01-.827-.826zM5.54 2.68a.837.837 0 00-.562-.202H2.486a.838.838 0 00-.79.567.829.829 0 00-.04.335 15.56 15.56 0 002.42 6.818l.004.006a15.356 15.356 0 004.736 4.724 15.634 15.634 0 006.8 2.417.839.839 0 00.836-.496.83.83 0 00.07-.337v-2.49-.014V14a.83.83 0 00-.716-.844c-.857-.113-1.7-.323-2.51-.624l-.002-.001a.838.838 0 00-.88.186L11.36 13.77a.826.826 0 01-.991.134 14.105 14.105 0 01-5.295-5.284.826.826 0 01.135-.994l1.053-1.05a.831.831 0 00.186-.876v-.002c-.303-.81-.513-1.65-.627-2.506a.831.831 0 00-.281-.511zM4.974.826a2.49 2.49 0 012.483 2.136l.001.008c.097.734.277 1.454.536 2.148a2.481 2.481 0 01-.56 2.62l-.003.004-.597.596a12.453 12.453 0 003.815 3.806l.599-.597.003-.004a2.488 2.488 0 012.622-.558 9.85 9.85 0 002.153.535h.008a2.488 2.488 0 012.14 2.511v2.477a2.482 2.482 0 01-1.692 2.361 2.49 2.49 0 01-1.035.12 17.288 17.288 0 01-7.525-2.672 17.01 17.01 0 01-5.234-5.223A17.212 17.212 0 01.01 3.534a2.481 2.481 0 011.473-2.496A2.49 2.49 0 012.485.826h2.49z" _fill="#CECECE"/>'
  }
})
