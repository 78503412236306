/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-look-account': {
    width: 9,
    height: 12,
    viewBox: '0 0 9 12',
    data: '<path pid="0" d="M9 5.625C9 5.005 8.495 4.5 7.875 4.5h-.563V2.812A2.816 2.816 0 004.5 0a2.816 2.816 0 00-2.813 2.813V4.5h-.562C.505 4.5 0 5.005 0 5.625v4.5c0 .62.505 1.125 1.125 1.125h6.75c.62 0 1.125-.505 1.125-1.125v-4.5zM2.812 2.812c0-.93.758-1.687 1.688-1.687.93 0 1.688.757 1.688 1.688V4.5H2.813V2.812z" _fill="#fff"/>'
  }
})
