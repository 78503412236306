/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_info': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<circle pid="0" cx="7" cy="7" r="7" _fill="#909090"/><path pid="1" d="M6.44 8.218a1.563 1.563 0 01.018-.63 2.27 2.27 0 01.216-.522c.09-.162.192-.312.306-.45.12-.138.231-.273.333-.405a2.55 2.55 0 00.252-.414.958.958 0 00.108-.45.886.886 0 00-.225-.612c-.144-.174-.36-.261-.648-.261-.198 0-.387.048-.567.144-.174.09-.33.216-.468.378l-.423-.387c.192-.216.414-.393.666-.531.258-.144.552-.216.882-.216.456 0 .819.129 1.089.387.276.252.414.603.414 1.053 0 .198-.036.378-.108.54a2.361 2.361 0 01-.27.459 6.957 6.957 0 01-.333.432 5.789 5.789 0 00-.324.432c-.096.15-.171.312-.225.486a1.342 1.342 0 00-.045.567H6.44zm.342 1.89a.514.514 0 01-.378-.153.567.567 0 01-.153-.405.56.56 0 01.153-.414.5.5 0 01.378-.162.5.5 0 01.378.162c.108.102.162.24.162.414a.55.55 0 01-.162.405.514.514 0 01-.378.153z" _fill="#fff"/>'
  }
})
