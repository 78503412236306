/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'img_delete': {
    width: 14,
    height: 13,
    viewBox: '0 0 14 13',
    data: '<path pid="0" d="M6.962 4.746L10.58 0h3.255L8.5 6.428 14 13h-3.225L7.008 8.134 3.24 13H0l5.5-6.572L.167 0H3.39l3.571 4.746z" _fill="#474748"/>'
  }
})
