/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-contact': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<path pid="0" d="M13.164 0H3.203c-1.06 0-1.922.862-1.922 1.922v1.185H0v1.281h1.281v3.203H0v1.281h1.281v3.203H0v1.281h1.281v1.121c0 1.06.862 1.922 1.922 1.922h9.961c1.06 0 1.922-.862 1.922-1.922V1.922C15.086.862 14.224 0 13.164 0zm.64 14.477c0 .354-.287.641-.64.641H3.203a.641.641 0 01-.64-.64v-1.122h.288v-.001l.032.001a.64.64 0 100-1.28H2.562V8.871h.289v-.001l.032.001a.64.64 0 100-1.281l-.032.002V7.59h-.289V4.388h.289v-.002l.032.002a.64.64 0 100-1.281l-.032.001v-.001h-.289V1.922c0-.353.288-.64.641-.64h9.961c.353 0 .64.287.64.64v12.555zm-3.619-6.446c.32-.391.51-.89.51-1.433a2.277 2.277 0 00-2.273-2.274 2.277 2.277 0 00-2.275 2.274c0 .554.2 1.063.53 1.458a3.88 3.88 0 00-2.097 3.443h1.281a2.597 2.597 0 012.595-2.595A2.597 2.597 0 0111.05 11.5h1.281a3.88 3.88 0 00-2.146-3.468zM8.422 5.605a.994.994 0 010 1.986.994.994 0 010-1.986z" _fill="#171D2D"/>'
  }
})
