/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_success': {
    width: 109,
    height: 128,
    viewBox: '0 0 109 128',
    data: '<path pid="0" _stroke="#000" d="M.424 52.734l47 75M46.551 127.781l62-127"/>'
  }
})
