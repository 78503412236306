/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-email': {
    width: 21,
    height: 18,
    viewBox: '0 0 21 18',
    data: '<path pid="0" d="M17.07 9.88L11 15.94V18h2.06l6.06-6.07-2.05-2.05zm3.63-.3L19.42 8.3a.517.517 0 00-.38-.17c-.15.01-.29.06-.39.17l-1 1 2.05 2 1-1c.19-.2.19-.52 0-.72zM9 14H2V4l8 5 8-5v2h2V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h7v-2zm9-12l-8 5-8-5h16z" _fill="#CECECE"/>'
  }
})
