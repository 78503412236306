/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-fail': {
    width: 23,
    height: 23,
    viewBox: '0 0 23 23',
    data: '<circle pid="0" opacity=".1" cx="11.5" cy="11.5" r="11.5" _fill="#FF2626"/><path pid="1" d="M15.78 14.364l-2.83-2.828 2.83-2.828a1 1 0 00-1.416-1.415l-2.828 2.83-2.828-2.83a1 1 0 10-1.415 1.415l2.83 2.828-2.83 2.828a1 1 0 101.415 1.415l2.828-2.829 2.828 2.83a1 1 0 101.415-1.416z" _fill="#FF2626"/>'
  }
})
