/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-password': {
    width: 16,
    height: 20,
    viewBox: '0 0 16 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 8V5a5 5 0 1110 0v3h1a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2v-8a2 2 0 012-2h1zm-1 2v8h12v-8H2zm3-2h6V5a3 3 0 00-6 0v3zm5 4h2v4h-2v-4z" _fill="#CECECE"/>'
  }
})
