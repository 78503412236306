/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-description': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<path pid="0" d="M18 0H2C.897 0 0 .897 0 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V2c0-1.103-.897-2-2-2zM2 16V2h16l.002 14H2z" _fill="#CECECE"/><path pid="1" d="M4 4h12v2H4V4zm0 4h12v2H4V8zm0 4h6v2H4v-2z" _fill="#CECECE"/>'
  }
})
