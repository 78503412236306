/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-modify': {
    width: 12,
    height: 16,
    viewBox: '0 0 12 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.75 14h10.5a.75.75 0 110 1.5H.75a.75.75 0 110-1.5zM0 10.25l7.5-7.5L9.75 5l-7.5 7.5H0v-2.25zM8.25 2L9.75.5 12 2.75l-1.5 1.5L8.25 2z" _fill="#CECECE"/>'
  }
})
