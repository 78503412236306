/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-eye-close': {
    width: 51,
    height: 43,
    viewBox: '0 0 51 43',
    data: '<path pid="0" d="M28.233 7.737a31.75 31.75 0 00-2.94-.18c-4.343.02-8.845 1.077-13.106 3.1-3.165 1.566-6.247 3.775-8.923 6.5C1.949 18.548.27 20.562 0 22.712c.032 1.861 2.03 4.159 3.264 5.554 2.51 2.618 5.511 4.764 8.923 6.5.115.056.232.111.348.166l-3.164 5.527L13.67 43 36.916 2.53 32.776 0l-4.544 7.737zm9.815 2.76l-3.159 5.474c1.453 1.888 2.316 4.216 2.316 6.74 0 6.293-5.334 11.394-11.915 11.394-.284 0-.56-.031-.84-.05l-2.09 3.619a31.29 31.29 0 002.933.191c4.347-.02 8.847-1.088 13.104-3.1 3.164-1.565 6.249-3.774 8.926-6.499 1.314-1.391 2.992-3.405 3.263-5.554-.032-1.862-2.03-4.16-3.263-5.555-2.51-2.618-5.515-4.764-8.926-6.5-.116-.055-.233-.105-.35-.16zm-12.758.822c.289 0 .575.011.858.03L23.7 15.59c-3.436.696-6.015 3.62-6.015 7.12 0 .879.162 1.72.46 2.5v.003l-2.454 4.252a11.014 11.014 0 01-2.316-6.752c0-6.292 5.334-11.393 11.915-11.393zm7.123 8.938l-5.517 9.559c3.417-.71 5.977-3.62 5.977-7.107 0-.864-.172-1.684-.46-2.452z" _fill="#000"/>'
  }
})
