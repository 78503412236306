/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-image': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M0 5a5 5 0 015-5h50a5 5 0 015 5v50a5 5 0 01-5 5H5a5 5 0 01-5-5V5z" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M55 1H5a4 4 0 00-4 4v50a4 4 0 004 4h50a4 4 0 004-4V5a4 4 0 00-4-4zM5 0a5 5 0 00-5 5v50a5 5 0 005 5h50a5 5 0 005-5V5a5 5 0 00-5-5H5z" _fill="#CCC"/><path pid="2" d="M34.5 23.25h-9a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25zm-9 1.5h9a.75.75 0 01.75.75v6.27l-2.4-2.047a2.077 2.077 0 00-2.64 0l-5.46 4.552V25.5a.75.75 0 01.75-.75zm9 10.5h-8.58l5.25-4.38a.585.585 0 01.697 0l3.383 2.88v.75a.75.75 0 01-.75.75z" _fill="#C4C4C4"/><path pid="3" d="M27 28.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" _fill="#C4C4C4"/>'
  }
})
