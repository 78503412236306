/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-next': {
    width: 13,
    height: 21,
    viewBox: '0 0 13 21',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.94 10.5L.908 3.028 3.092.972 12.06 10.5l-8.968 9.528-2.184-2.056L7.94 10.5z" _fill="#fff"/>'
  }
})
