/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-tooltip-icon': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<path pid="0" d="M6.302 10.29H7.63V6.304H6.302v3.984zm.664-6.641c-.332 0-.664.332-.664.664s.332.664.664.664.664-.332.664-.664-.332-.664-.664-.664zm0-2.657A5.952 5.952 0 00.989 6.97a5.952 5.952 0 005.977 5.977 5.952 5.952 0 005.977-5.977A5.952 5.952 0 006.966.992zm0 10.625A4.62 4.62 0 012.317 6.97 4.62 4.62 0 016.966 2.32a4.62 4.62 0 014.648 4.649 4.62 4.62 0 01-4.648 4.648z" _fill="#171D2D"/>'
  }
})
