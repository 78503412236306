/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-notification': {
    width: 16,
    height: 18,
    viewBox: '0 0 16 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.537 1.957C5.835.988 6.707 0 8 0c1.293 0 2.166.989 2.466 1.956a.14.14 0 00.025.023l.009.005c.753.3 1.639.78 2.332 1.658.698.886 1.153 2.115 1.153 3.83 0 1.71.186 2.647.46 3.29.24.565.556.936 1.006 1.464l.206.242c.829.985.046 2.367-1.23 2.367H1.577c-1.274 0-2.07-1.375-1.234-2.367.071-.085.14-.165.206-.242.45-.528.765-.899 1.006-1.463.274-.644.461-1.582.462-3.291M10.5 1.983zm-4.963-.026a.127.127 0 01-.023.02l-.01.006c-.754.301-1.64.78-2.333 1.658-.699.885-1.153 2.114-1.153 3.83M8.001 1.29c-.513 0-1.002.414-1.191 1.045v.002c-.123.403-.461.704-.8.84-.636.253-1.286.62-1.782 1.248-.49.621-.879 1.561-.879 3.047 0 1.783-.192 2.914-.563 3.783-.322.756-.771 1.281-1.218 1.802-.064.076-.13.151-.193.228a.137.137 0 00-.036.076.136.136 0 00.016.076c.025.046.087.107.223.107h12.85c.131 0 .192-.059.217-.107a.128.128 0 00-.018-.152 35.18 35.18 0 00-.194-.228c-.446-.52-.896-1.046-1.217-1.802-.37-.869-.561-2-.561-3.783 0-1.484-.389-2.424-.88-3.046-.496-.63-1.147-.997-1.781-1.25-.343-.136-.677-.441-.799-.838v-.002C9.003 1.704 8.513 1.29 8 1.29z" _fill="#171D2D"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M5.27 13.485c.368 0 .666.289.666.645v.645c0 .513.21 1.005.584 1.368a2.03 2.03 0 001.412.567 2.03 2.03 0 001.412-.567c.375-.363.585-.855.585-1.368v-.645c0-.356.298-.645.666-.645.367 0 .665.289.665.645v.645c0 .855-.35 1.676-.974 2.28A3.383 3.383 0 017.932 18c-.882 0-1.729-.34-2.353-.945a3.175 3.175 0 01-.975-2.28v-.645c0-.356.298-.645.666-.645z" _fill="#171D2D"/>'
  }
})
