/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-contact-email': {
    width: 18,
    height: 12,
    viewBox: '0 0 18 12',
    data: '<path pid="0" d="M1.88 0h13.25c1.25 0 1.88.59 1.88 1.79v8.42c0 1.19-.63 1.79-1.88 1.79H1.88C.63 12 0 11.4 0 10.21V1.79C0 .59.63 0 1.88 0zM8.5 8.6l6.74-5.53c.24-.2.43-.66.13-1.07-.29-.41-.82-.42-1.17-.17L8.5 5.69 2.81 1.83c-.35-.25-.88-.24-1.17.17-.3.41-.11.87.13 1.07L8.5 8.6z" _fill="#16B853"/>'
  }
})
