/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-more-white': {
    width: 25,
    height: 13,
    viewBox: '0 0 25 13',
    data: '<path pid="0" d="M24.875 1.62c0-.972-.622-1.62-1.555-1.62H1.555C.622 0 0 .648 0 1.62v9.717c0 .972.622 1.62 1.555 1.62H23.32c.933 0 1.555-.648 1.555-1.62V1.62zm-1.555 9.717H1.555V1.62H23.32v9.717z" _fill="#CECECE"/><path pid="1" d="M9.328 6.478c0 .43-.164.842-.455 1.146a1.524 1.524 0 01-1.1.474c-.412 0-.807-.17-1.099-.474a1.655 1.655 0 01-.455-1.146c0-.43.164-.841.455-1.145a1.524 1.524 0 011.1-.474c.412 0 .807.17 1.099.474.291.304.455.716.455 1.145zM13.992 6.478c0 .43-.164.842-.455 1.146a1.524 1.524 0 01-1.1.474c-.412 0-.807-.17-1.099-.474a1.654 1.654 0 01-.455-1.146c0-.43.164-.841.455-1.145a1.524 1.524 0 011.1-.474c.412 0 .807.17 1.099.474.291.304.455.716.455 1.145zM18.656 6.478c0 .43-.163.842-.455 1.146a1.524 1.524 0 01-1.1.474c-.412 0-.807-.17-1.099-.474a1.655 1.655 0 01-.455-1.146c0-.43.164-.841.455-1.145a1.524 1.524 0 011.1-.474c.412 0 .807.17 1.099.474.291.304.455.716.455 1.145z" _fill="#CECECE"/>'
  }
})
