/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_select_arrow': {
    width: 17,
    height: 10,
    viewBox: '0 0 17 10',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 1.445L1.436 0 8.5 7.11 15.565 0 17 1.445 8.5 10 0 1.445z" _fill="#BDBDBD"/>'
  }
})
