/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sms_sent': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<circle pid="0" opacity=".1" cx="30" cy="30" r="30" _fill="#1F28CF"/><path pid="1" d="M42.188 19H17.811A2.813 2.813 0 0015 21.813v16.875a2.813 2.813 0 002.813 2.812h24.375A2.813 2.813 0 0045 38.687V21.814A2.813 2.813 0 0042.187 19zm-.675 1.875L30 29.097l-11.512-8.222h23.025zm1.612 17.813c0 .517-.42.937-.938.937H17.814a.937.937 0 01-.938-.938V22.029l12.581 8.981a.937.937 0 001.088 0l12.581-8.98v16.659z" _fill="#1F28CF"/>'
  }
})
