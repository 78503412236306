/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-visa': {
    width: 37,
    height: 12,
    viewBox: '0 0 37 12',
    data: '<path pid="0" d="M19.136 3.82c-.022 1.666 1.483 2.595 2.616 3.147 1.164.567 1.556.93 1.55 1.438-.008.775-.928 1.118-1.789 1.13-1.502.024-2.375-.405-3.07-.73l-.54 2.534c.696.32 1.986.6 3.323.613 3.14 0 5.195-1.55 5.206-3.954.012-3.051-4.219-3.22-4.19-4.584.01-.413.404-.855 1.269-.967.428-.056 1.608-.1 2.948.517l.525-2.45A8.012 8.012 0 0024.186 0c-2.955 0-5.034 1.572-5.05 3.82zM32.034.212c-.574 0-1.057.335-1.272.848l-4.486 10.713h3.138l.624-1.726h3.834l.362 1.726H37L34.587.212h-2.553zm.439 3.123l.905 4.341h-2.48l1.575-4.34zM15.33.211l-2.473 11.56h2.99L18.32.212h-2.989zm-4.423 0L7.796 8.08 6.537 1.39C6.39.641 5.807.21 5.158.21H.071L0 .547c1.044.227 2.231.592 2.95.983.44.24.565.448.71 1.016l2.384 9.226h3.16L14.048.212h-3.14" _fill="url(#paint0_linear)"/><defs><linearGradient id="paint0_linear" x1="17.01" y1="12.192" x2="17.357" y2="-.083" gradientUnits="userSpaceOnUse"><stop stop-color="#222357"/><stop offset="1" stop-color="#254AA5"/></linearGradient></defs>'
  }
})
