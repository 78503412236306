/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-radio': {
    width: 11,
    height: 11,
    viewBox: '0 0 11 11',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.5 9a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm0 2a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" _fill="#C4C4C4"/>'
  }
})
