/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-choose-none': {
    width: 11,
    height: 11,
    viewBox: '0 0 11 11',
    data: '<circle pid="0" cx="5.5" cy="5.5" r="4.5" _stroke="#C4C4C4" stroke-width="2"/>'
  }
})
