/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-restart': {
    width: 15,
    height: 19,
    viewBox: '0 0 15 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 .196L14.887 9.5 0 18.804V.196zm2 3.608v11.392L11.113 9.5 2 3.804z" _fill="#CECECE"/>'
  }
})
