/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-eye-open': {
    width: 51,
    height: 30,
    viewBox: '0 0 51 30',
    data: '<path pid="0" d="M32.54 14.997c0 3.978-3.365 7.202-7.517 7.202s-7.518-3.224-7.518-7.202c0-3.978 3.366-7.203 7.518-7.203 4.152 0 7.518 3.225 7.518 7.203zM25.037 0c-4.299.02-8.755 1.066-12.973 3.07C8.931 4.617 5.88 6.803 3.23 9.5 1.93 10.878.27 12.871 0 15c.032 1.843 2.008 4.117 3.23 5.498 2.484 2.592 5.457 4.716 8.833 6.434A30.412 30.412 0 0025.036 30c4.303-.02 8.758-1.078 12.972-3.07 3.132-1.548 6.185-3.735 8.835-6.433 1.301-1.377 2.961-3.37 3.23-5.498-.032-1.843-2.009-4.117-3.23-5.498-2.485-2.591-5.459-4.714-8.835-6.432A30.586 30.586 0 0025.036 0zm-.003 3.724c6.514 0 11.795 5.049 11.795 11.277 0 6.228-5.28 11.277-11.795 11.277-6.514 0-11.795-5.05-11.795-11.277 0-6.228 5.28-11.277 11.795-11.277z" _fill="#000"/>'
  }
})
