/* eslint-disable */
require('./account')
require('./add-doc')
require('./add-image')
require('./doc')
require('./ic_attach')
require('./ic_audience')
require('./ic_audio')
require('./ic_delete')
require('./ic_info')
require('./ic_pdf_download')
require('./ic_pic_doc')
require('./ic_pic_image')
require('./ic_round-refresh')
require('./ic_select_arrow')
require('./ic_select')
require('./ic_success_audio')
require('./ic_success')
require('./ic-acceuil')
require('./ic-accueil-ads')
require('./ic-action-del')
require('./ic-action-pause')
require('./ic-action-see')
require('./ic-action-suspend')
require('./ic-action-trash')
require('./ic-action-use')
require('./ic-add')
require('./ic-arrow-back')
require('./ic-back')
require('./ic-brawo')
require('./ic-camera')
require('./ic-campaign-in-app')
require('./ic-campaign-sms')
require('./ic-campaign')
require('./ic-card')
require('./ic-choose-none')
require('./ic-choosed')
require('./ic-clics')
require('./ic-contact-email')
require('./ic-contact-localisation')
require('./ic-contact-phone')
require('./ic-contact')
require('./ic-delete')
require('./ic-description')
require('./ic-domaine')
require('./ic-down-arrow')
require('./ic-download-arrow')
require('./ic-edit-profile')
require('./ic-email-mobile')
require('./ic-email')
require('./ic-expense')
require('./ic-eye-close')
require('./ic-eye-open')
require('./ic-fail')
require('./ic-home')
require('./ic-info')
require('./ic-infos')
require('./ic-location')
require('./ic-look-account')
require('./ic-mastercard')
require('./ic-modify')
require('./ic-more-rema')
require('./ic-more-white')
require('./ic-more')
require('./ic-next-arrow')
require('./ic-next')
require('./ic-notif-green')
require('./ic-notification')
require('./ic-password')
require('./ic-paused')
require('./ic-phone')
require('./ic-radio-choosed')
require('./ic-radio')
require('./ic-rema')
require('./ic-restart')
require('./ic-see')
require('./ic-select-calendar')
require('./ic-smiley')
require('./ic-sucess')
require('./ic-tooltip-icon')
require('./ic-visa')
require('./ic-wallet')
require('./img_delete')
require('./img')
require('./sms_sent')
require('./sms')
