/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-action-del': {
    width: 13,
    height: 14,
    viewBox: '0 0 13 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.2 1.6a.6.6 0 00-.6.6v.6h3.6v-.6a.6.6 0 00-.6-.6H5.2zm4.2 1.2v-.6A1.8 1.8 0 007.6.4H5.2a1.8 1.8 0 00-1.8 1.8v.6H1A.6.6 0 001 4h.6v7.8a1.8 1.8 0 001.8 1.8h6a1.8 1.8 0 001.8-1.8V4h.6a.6.6 0 100-1.2H9.4zM2.8 4v7.8a.6.6 0 00.6.6h6a.6.6 0 00.6-.6V4H2.8zm2.4 1.8a.6.6 0 01.6.6V10a.6.6 0 11-1.2 0V6.4a.6.6 0 01.6-.6zm2.4 0a.6.6 0 01.6.6V10A.6.6 0 117 10V6.4a.6.6 0 01.6-.6z" _fill="#F44336"/>'
  }
})
