import './assets/style/sass/main.scss'
import './assets/style/sass/mixin.scss'
import './assets/style/css/tailwind.css'
import './assets/style/css/style.css'
import VeeValidate, { Validator } from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr'
import SvgIcon from 'vue-svgicon'
import '@/components/icons'
import { ClientTable } from 'vue-tables-2'
import DrawerLayout from 'vue-drawer-layout'
import AvatarCropper from 'vue-avatar-cropper'
import VuejsClipper from 'vuejs-clipper'
import DisableAutocomplete from 'vue-disable-autocomplete'
import { rtdbPlugin } from 'vuefire'
import VueHtml2pdf from 'vue-html2pdf'
import { Datetime } from 'vue-datetime'
import Multiselect from 'vue-multiselect'
import ReadMore from 'vue-read-more'
import VTooltip from 'v-tooltip'
import Popper from 'vue-popperjs'
import Popover from 'vue-js-popover'
import Notifications from 'vue-notification'
import Toasted from 'vue-toasted'
import VueChatScroll from 'vue-chat-scroll'
import Toast from 'vue-toastification'
import VueTippy, { TippyComponent } from 'vue-tippy'
import BubbleChat from 'vue-bubble-chat'
import VueZoomer from 'vue-zoomer'
import VueAudio from 'vue-audio-better'
import AudioRecorder from 'vue-audio-recorder'

// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
import 'vue-popperjs/dist/vue-popper.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/themes/google.css'
import 'tippy.js/themes/translucent.css'
import 'vue-toastification/dist/index.css'
// SSR

export default {
  install (Vue, options) {
    Vue.use(VeeValidate)
    Validator.localize('fr', fr)
    Vue.use(SvgIcon, {
      tagName: 'svgIcon'
    })
    Vue.use(AudioRecorder)
    Vue.use(VueAudio)
    Vue.use(ClientTable)
    Vue.use(DrawerLayout)
    Vue.use(AvatarCropper)
    Vue.use(VuejsClipper)
    Vue.use(DisableAutocomplete)
    Vue.use(rtdbPlugin)
    Vue.use(VueHtml2pdf)
    Vue.use(Datetime)
    Vue.component('multiselect', Multiselect)
    Vue.use(ReadMore)
    Vue.use(VTooltip)
    Vue.directive('tooltip', VTooltip)
    Vue.component('popper', Popper)
    Vue.use(Notifications)
    Vue.use(Toasted, {
      duration: 5000
    })
    Vue.use(VueChatScroll)
    Vue.use(VueZoomer)
    Vue.use(Popover, { tooltip: true })
    Vue.use(VueTippy)
    Vue.component('tippy', TippyComponent)
    Vue.toasted.register('myToastName', function (message) { return message }, {
      type: 'info',
      duration: 3000,
      className: ['your-custom-class']
    })
    Vue.use(Toast, options)
    Vue.use(BubbleChat)
  }
}
