/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-contact-phone': {
    width: 18,
    height: 17,
    viewBox: '0 0 18 17',
    data: '<path pid="0" d="M16.773 14.136l-2.396 2.378c-.354.362-.823.485-1.28.486-2.02-.06-3.929-1.053-5.496-2.072-2.573-1.871-4.934-4.192-6.416-6.997C.617 6.755-.05 5.254.014 3.94c.006-.494.139-.978.487-1.297L2.897.25c.498-.424.98-.277 1.298.216l1.928 3.656c.203.434.087.898-.216 1.207l-.883.883a.434.434 0 00-.09.252c.338 1.31 1.364 2.519 2.27 3.35.906.832 1.88 1.958 3.145 2.225.156.043.348.059.46-.045l1.027-1.045c.354-.268.865-.4 1.243-.18h.018l3.478 2.053c.51.32.564.939.198 1.315z" _fill="#16B853"/>'
  }
})
