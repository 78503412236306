/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-campaign-sms': {
    width: 58,
    height: 58,
    viewBox: '0 0 58 58',
    data: '<circle pid="0" cx="29" cy="29" r="29" _fill="#E5E5E5"/><path pid="1" d="M38.333 20.667H19.667A2.333 2.333 0 0017.333 23v14a2.333 2.333 0 002.334 2.333h18.666A2.333 2.333 0 0040.667 37V23a2.333 2.333 0 00-2.334-2.333zm0 4.942L29 31.304l-9.333-5.695V23h18.666v2.609z" _fill="#16B853"/>'
  }
})
