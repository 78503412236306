/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_audio': {
    width: 660,
    height: 896,
    viewBox: '0 0 660 896',
    data: '<path pid="0" d="M660 390c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8 0 140.3-113.7 254-254 254S76 530.3 76 390c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8 0 168.7 126.6 307.9 290 327.6V820H144.7c-13.7 0-24.7 14.3-24.7 32v36c0 4.4 2.8 8 6.2 8h407.6c3.4 0 6.2-3.6 6.2-8v-36c0-17.7-11-32-24.7-32H366V718.1c165.3-18 294-158 294-328.1zM330 560c93.9 0 170-75.2 170-168V168C500 75.2 423.9 0 330 0S160 75.2 160 168v224c0 92.8 76.1 168 170 168zm-94-392c0-50.6 41.9-92 94-92s94 41.4 94 92v224c0 50.6-41.9 92-94 92s-94-41.4-94-92V168z" _fill="#000"/>'
  }
})
