/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-down-arrow': {
    width: 16,
    height: 10,
    viewBox: '0 0 16 10',
    data: '<path pid="0" d="M1.377 1l6.811 7L15 1" _stroke="#474748" stroke-width="2.5"/>'
  }
})
