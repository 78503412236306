/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-next-arrow': {
    width: 13,
    height: 21,
    viewBox: '0 0 13 21',
    data: '<path pid="0" d="M2 2l8 8.5L2 19" _stroke="#fff" stroke-width="3"/>'
  }
})
