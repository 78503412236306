import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import install from './install'
import { i18n } from './i18n'
import firebase from 'firebase'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import LogRocket from 'logrocket'

Vue.use(install)
Vue.component(VueCropper)

Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.devtools = process.env.NODE_ENV !== 'production'
let app = ''
firebase.initializeApp({
  apiKey: 'AIzaSyB9MJqfFFYGqXYCWgP_FysnBE73BYHCatQ',
  authDomain: 'rema-da2f4.firebaseapp.com',
  databaseURL: 'https://rema-da2f4.firebaseio.com',
  projectId: 'rema-da2f4',
  storageBucket: 'rema-da2f4.appspot.com',
  messagingSenderId: '822161756374'
})
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
})
LogRocket.init('mwt1qt/rema-ads')

export const firebaseDb = firebase.database()
export const firebaseStorage = firebase.storage()
let paydunya = require('paydunya')
const setupDunya = new paydunya.Setup({
  masterKey: 'mlbbrcjJ-RgO0-RSR3-1sXa-xCH1RKKutNkf',
  privateKey: 'test_private_IxdqN55UpQiqc77bKp1ruIXeNgc',
  publicKey: 'test_public_r0pmxBAELMj271HJuSuXtv53g05',
  token: 'l7HYdJ8KuP5zgpdRKm05',
  mode: 'test' // optional. use in sandbox mode.
})
const storeDunya = new paydunya.Store({
  name: 'REMA Business', // Seul le nom est requis
  tagline: "élégance n'a pas de prix",
  phoneNumber: '229 62922091',
  postalAddress: 'Cotonou - Fidjrosse',
  websiteURL: 'http://www.remaapp.com'
})
export const InvoiceDunya = new paydunya.CheckoutInvoice(setupDunya, storeDunya)
