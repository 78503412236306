import Vue from 'vue'
import Router from 'vue-router'
import pathResolver from '@/plugins/loader'
import firebase from 'firebase'

Vue.use(Router)

const routes = [
  {
    path: '*',
    name: 'Login',
    component: pathResolver(null, 'login')
  },
  {
    path: '/',
    name: 'Login',
    component: pathResolver(null, 'login')
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: pathResolver(null, 'sign-up')
  },
  {
    path: '/confirm/:id',
    name: 'confirm',
    component: pathResolver(null, 'confirm'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/success-card-funding/:id/:idBefore/:isOnSite',
    name: 'success',
    component: pathResolver(null, 'success'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: pathResolver(null, 'home'),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: pathResolver('dashboard'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/cards',
        name: 'Cards',
        component: pathResolver('cards'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/histories',
        name: 'Histories',
        component: pathResolver('histories'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/requests',
        name: 'Requests',
        component: pathResolver('requests'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/audience',
        name: 'Audience',
        component: pathResolver('audience'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/collaborators',
        name: 'Collaborators',
        component: pathResolver('collaborators'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/settings',
        name: 'Settings',
        component: pathResolver('settings'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/wallet',
        name: 'Wallet',
        component: pathResolver('wallet'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/code',
        name: 'Code',
        component: pathResolver('code'),
        meta: {
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/policies/terms',
    name: 'Terms',
    component: pathResolver('errors', '404'),
    redirect: '/',
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/campaigns/:id',
    name: 'Campaigns',
    component: pathResolver('campaigns'),
    meta: {
      requireAuth: true
    },
    props: (route) => ({
      ...route.params
    })
  },
  {
    path: '/campaign-sms/:id',
    name: 'CampaignSms',
    component: pathResolver('campaign-sms'),
    meta: {
      requireAuth: true
    },
    props: (route) => ({
      ...route.params
    })
  },
  {
    path: '/policies/privacy',
    name: 'Privacy',
    component: pathResolver('errors', '404'),
    redirect: '/',
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/*',
    name: 'E404',
    component: pathResolver('errors', '404'),
    meta: {
      requireAuth: false
    }
  }
]
const router = new Router({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    // retourner la position désirée
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return { selector: to.hash }
      } else {
        return { x: 0, y: 0 }
      }
    }
  }
})
router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requireAuth = to.matched.some(record => record.meta.requireAuth)

  if (requireAuth && !currentUser) next('login')
  else if (!requireAuth && currentUser) next('dashboard')
  else next()
})
export default router
