/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-domaine': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.027 1.688v4.339h-4.34v-4.34h4.34zm10.285 0h-4.339v4.339h4.34v-4.34zM6.027 11.973h-4.34v4.34h4.34v-4.34zm10.285 0h-4.339v4.34h4.34v-4.34zM7.714 6.59c0 .622-.503 1.125-1.125 1.125H1.125A1.125 1.125 0 010 6.59V1.125C0 .504.504 0 1.125 0h5.464c.622 0 1.125.504 1.125 1.125v5.464zM18 6.59c0 .622-.504 1.125-1.125 1.125h-5.464a1.125 1.125 0 01-1.125-1.125V1.125C10.286.504 10.789 0 11.41 0h5.464C17.496 0 18 .504 18 1.125v5.464zM7.714 16.875c0 .621-.503 1.125-1.125 1.125H1.125A1.125 1.125 0 010 16.875v-5.464c0-.622.504-1.125 1.125-1.125h5.464c.622 0 1.125.503 1.125 1.125v5.464zm10.286 0c0 .621-.504 1.125-1.125 1.125h-5.464a1.125 1.125 0 01-1.125-1.125v-5.464c0-.622.503-1.125 1.125-1.125h5.464c.621 0 1.125.503 1.125 1.125v5.464z" _fill="#CECECE"/>'
  }
})
