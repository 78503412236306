/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-radio-choosed': {
    width: 11,
    height: 11,
    viewBox: '0 0 11 11',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.5 8a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0 3a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" _fill="#16B853"/>'
  }
})
