/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-action-suspend': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 15A7 7 0 108 1a7 7 0 000 14zM6.6 10.1V5.9M9.4 10.1V5.9" _stroke="#CECECE" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
