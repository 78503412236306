/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_select': {
    width: 12,
    height: 6,
    viewBox: '0 0 12 6',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.53 1.142a.549.549 0 10-.723-.824L6 4.54 1.193.318a.549.549 0 10-.724.824L6 6l5.53-4.858z" _fill="#828282"/>'
  }
})
