/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-doc': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<path pid="0" d="M0 5a5 5 0 015-5h50a5 5 0 015 5v50a5 5 0 01-5 5H5a5 5 0 01-5-5V5z" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M55 1H5a4 4 0 00-4 4v50a4 4 0 004 4h50a4 4 0 004-4V5a4 4 0 00-4-4zM5 0a5 5 0 00-5 5v50a5 5 0 005 5h50a5 5 0 005-5V5a5 5 0 00-5-5H5z" _fill="#CCC"/><path pid="2" d="M35.625 27.778v6.847a1.687 1.687 0 01-1.688 1.688h-7.874a1.687 1.687 0 01-1.688-1.688v-11.25a1.687 1.687 0 011.688-1.688h3.471c.298 0 .585.119.795.33l4.967 4.966c.21.211.329.497.329.795z" _stroke="#C4C4C4" stroke-width="1.125" stroke-linejoin="round"/><path pid="3" d="M30 21.969v4.218a1.125 1.125 0 001.125 1.125h4.219M27.188 30.125h5.625M27.188 32.938h5.625" _stroke="#C4C4C4" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
