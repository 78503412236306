/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-action-trash': {
    width: 13,
    height: 14,
    viewBox: '0 0 13 14',
    data: '<path pid="0" d="M1 3.4h10.8M4 3.4V2.2A1.2 1.2 0 015.2 1h2.4a1.2 1.2 0 011.2 1.2v1.2m1.8 0v8.4A1.2 1.2 0 019.4 13h-6a1.2 1.2 0 01-1.2-1.2V3.4h8.4zM5.2 6.4V10M7.6 6.4V10" _stroke="#F44336" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
