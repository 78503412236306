import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [ createPersistedState() ],
  state: {
    currentPost: {},
    isSMS: false
  },
  mutations: {
    UPDATE_POST (state, user) {
      state.currentPost = user
    },
    UPDATE_STATE (state, isSMS) {
      state.isSMS = isSMS
    }
  },
  getters: {
    getCurrentPost: state => {
      return state.currentPost
    },
    getState: state => {
      return state.isSMS
    }
  },
  actions: {
    createPost ({ commit }, post) {
      commit('UPDATE_POST', post)
    },
    createState ({ commit }, isSms) {
      commit('UPDATE_STATE', isSms)
    }
  }
})
