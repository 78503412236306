/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'img': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M11.5.25h-9A2.25 2.25 0 00.25 2.5v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9A2.25 2.25 0 0011.5.25zm-9 1.5h9a.75.75 0 01.75.75v6.27l-2.4-2.047a2.077 2.077 0 00-2.64 0l-5.46 4.552V2.5a.75.75 0 01.75-.75zm9 10.5H2.92l5.25-4.38a.585.585 0 01.697 0l3.383 2.88v.75a.75.75 0 01-.75.75z" _fill="#C4C4C4"/><path pid="1" d="M4 5.5a1.125 1.125 0 100-2.25A1.125 1.125 0 004 5.5z" _fill="#C4C4C4"/>'
  }
})
