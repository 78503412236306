/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-add': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<path pid="0" d="M12.007 5.464v1.512h-4.68v5.076H5.671V6.976H.955V5.464h4.716V.388h1.656v5.076h4.68z" _fill="#CECECE"/>'
  }
})
