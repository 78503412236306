/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-arrow-back': {
    width: 22,
    height: 24,
    viewBox: '0 0 22 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.485 22.607L.94 13.06a1.5 1.5 0 010-2.122l9.546-9.546a1.5 1.5 0 112.122 2.122L5.62 10.5H22v3H5.621l6.986 6.985a1.5 1.5 0 11-2.122 2.122z" _fill="#16B853"/>'
  }
})
