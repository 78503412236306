/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-back': {
    width: 11,
    height: 16,
    viewBox: '0 0 11 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.433.234l1.706 1.828-6.203 5.786 5.953 6.043-1.78 1.754L.351 7.772 8.433.234z" _fill="#474748"/>'
  }
})
