/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-info': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M8 14h2V8H8v6zM9 4c-.5 0-1 .5-1 1s.5 1 1 1 1-.5 1-1-.5-1-1-1zm0-4C4 0 0 4 0 9s4 9 9 9 9-4 9-9-4-9-9-9zm0 16c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7z" _fill="#171D2D"/>'
  }
})
