/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_success_audio': {
    width: 109,
    height: 132,
    viewBox: '0 0 109 132',
    data: '<path pid="0" _stroke="#16B853" stroke-width="10" d="M4.237 54.345l47 75M42.507 129.806l62-127"/>'
  }
})
