/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-location': {
    width: 12,
    height: 18,
    viewBox: '0 0 12 18',
    data: '<path pid="0" d="M6 16c.437 0 1.479-1.187 2.411-3.312C9.357 10.534 10 7.874 10 6.353 10 3.924 8.183 2 6 2S2 3.924 2 6.353c0 1.52.643 4.181 1.589 6.335C4.52 14.813 5.563 16 6 16zm0 2C2.686 18 0 9.862 0 6.353 0 2.844 2.686 0 6 0s6 2.844 6 6.353S9.314 18 6 18zM6 8a2 2 0 100-4 2 2 0 000 4z" _fill="#CECECE"/>'
  }
})
