/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-download-arrow': {
    width: 75,
    height: 113,
    viewBox: '0 0 75 113',
    data: '<path pid="0" d="M34.498 110.569a5 5 0 007.07-.067l31.519-32.118a5 5 0 10-7.138-7.005L37.934 99.93 9.383 71.913a5 5 0 10-7.004 7.138l32.119 31.518zM32 1.047l1 106 10-.094-1-106-10 .094z" _fill="#000"/>'
  }
})
