/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-contact-localisation': {
    width: 15,
    height: 20,
    viewBox: '0 0 15 20',
    data: '<path pid="0" d="M7.01 0c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 110-5 2.5 2.5 0 010 5z" _fill="#16B853"/>'
  }
})
