/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-wallet': {
    width: 16,
    height: 15,
    viewBox: '0 0 16 15',
    data: '<path pid="0" d="M14 2.667V2c0-1.103-.897-2-2-2H2C.897 0 0 .897 0 2v10.667c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2zM2 1.333h10c.368 0 .667.3.667.667v.667H2a.667.667 0 010-1.334zm12 12H2a.667.667 0 01-.667-.666V3.886C1.542 3.96 1.766 4 2 4h12c.368 0 .667.299.667.667v1.666H11a2.336 2.336 0 00-2.333 2.334A2.336 2.336 0 0011 11h3.667v1.667a.667.667 0 01-.667.666zm.667-3.666h-1.56a2.318 2.318 0 00-.002-2h1.562v2zm-4.667-1c0-.552.449-1 1-1a1.001 1.001 0 01.71 1.706A1.001 1.001 0 0110 8.667z" _fill="#171D2D"/>'
  }
})
