/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-campaign-in-app': {
    width: 58,
    height: 58,
    viewBox: '0 0 58 58',
    data: '<circle pid="0" cx="29" cy="29" r="29" _fill="#E5E5E5"/><path pid="1" d="M35.313 13H23.645A3.646 3.646 0 0020 16.646v24.791a3.646 3.646 0 003.646 3.646h11.666a3.646 3.646 0 003.646-3.645V16.645A3.646 3.646 0 0035.313 13zm-5.834 30.625a2.185 2.185 0 01-2.187-2.188c0-1.21.977-2.187 2.187-2.187s2.188.977 2.188 2.188c0 1.21-.977 2.187-2.188 2.187zm6.563-5.833H22.917V17.375h13.125v20.417z" _fill="#16B853"/>'
  }
})
