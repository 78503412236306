/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_delete': {
    width: 59,
    height: 59,
    viewBox: '0 0 59 59',
    data: '<circle pid="0" cx="29.5" cy="29.5" r="29.5" _fill="#E0E0E0"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M26.172 30L14.086 17.914l2.828-2.828L29 27.172l12.086-12.086 2.828 2.828L31.828 30l12.086 12.086-2.828 2.828L29 32.828 16.914 44.914l-2.828-2.828L26.172 30z" _fill="#828282"/>'
  }
})
