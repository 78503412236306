/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-expense': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<circle pid="0" opacity=".1" cx="30" cy="30" r="30" _fill="#16B853"/><path pid="1" d="M30.59 21.193h-3.895a.833.833 0 110-1.667h3.895a.833.833 0 110 1.667zM38.025 26.682h-11.33a.833.833 0 110-1.667h11.33a.833.833 0 110 1.667zM38.025 32.17h-11.33a.833.833 0 110-1.666h11.33a.833.833 0 110 1.667z" _fill="#16B853"/><path pid="2" d="M43.794 15H21.607a.819.819 0 00-.833.803v.005l-.198 19.609h-4.704a.898.898 0 00-.872.868v3.099c0 2.971 2.314 5.41 5.186 5.603.049.009.098.013.147.013h18.49c.032 0 .063-.002.093-.005.07.002.14.005.21.005 3.04 0 5.54-2.52 5.54-5.617v-23.55c0-.46-.411-.833-.871-.833zM16.668 39.383v-2.3h16.916v2.3a5.633 5.633 0 001.612 3.95H20.533c-2.121 0-3.866-1.772-3.866-3.95zm26.333 0c0 2.179-1.752 3.95-3.876 3.95-2.122 0-3.874-1.772-3.874-3.95v-3.098c0-.46-.358-.868-.818-.868h-12.19l.19-18.75H43v22.716z" _fill="#16B853"/>'
  }
})
