/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_pic_image': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19V5h14l.002 14H5z" _fill="#000"/><path pid="1" d="M10 14l-1-1-3 4h12l-5-7-3 4z" _fill="#000"/>'
  }
})
