/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic_round-refresh': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<path pid="0" d="M13.65 3.35a7.95 7.95 0 00-6.48-2.31C3.5 1.41.48 4.39.07 8.06-.48 12.91 3.27 17 8 17a7.98 7.98 0 007.21-4.56c.32-.67-.16-1.44-.9-1.44-.37 0-.72.2-.88.53a5.994 5.994 0 01-6.8 3.31c-2.22-.49-4.01-2.3-4.48-4.52A6.002 6.002 0 018 3c1.66 0 3.14.69 4.22 1.78l-1.51 1.51c-.63.63-.19 1.71.7 1.71H15c.55 0 1-.45 1-1V3.41c0-.89-1.08-1.34-1.71-.71l-.64.65z" _fill="#16B853"/>'
  }
})
