/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-campaign': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.35 2.683a6.35 6.35 0 106.967 6.967H7.35V2.683zM.35 9A7.65 7.65 0 018 1.35h.65v7h7V9A7.65 7.65 0 11.35 9z" _fill="#171D2D"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M10 7V0a6.994 6.994 0 015.362 2.5A6.974 6.974 0 0117 7h-7zm5.551-1.3a5.675 5.675 0 00-1.184-2.364A5.696 5.696 0 0011.3 1.45V5.7h4.251z" _fill="#171D2D"/>'
  }
})
