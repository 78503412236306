/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-clics': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<circle pid="0" opacity=".1" cx="30" cy="30" r="30" _fill="#F44336"/><path pid="1" d="M46.09 26.636l-6.414-7.049a3.265 3.265 0 00-5.444 1.422 3.267 3.267 0 00-5.119 1.704 3.269 3.269 0 00-5.109 1.673l-3.023-3.023a3.265 3.265 0 00-4.618.01 3.26 3.26 0 000 4.608l11.614 11.614-6.888 1.081a3.821 3.821 0 00-3.265 3.765A2.562 2.562 0 0020.382 45h16.085a9.32 9.32 0 006.634-2.748l2.684-2.684a9.304 9.304 0 002.744-6.625 9.356 9.356 0 00-2.439-6.307zm-1.512 11.726l-2.683 2.684a7.626 7.626 0 01-5.428 2.248H20.382a.853.853 0 01-.853-.853 2.11 2.11 0 011.814-2.078l8.555-1.344a.854.854 0 00.47-1.445L17.57 24.775a1.56 1.56 0 012.206-2.206l8.535 8.535a.853.853 0 101.204-1.208l-3.485-3.485a1.557 1.557 0 01.01-2.206 1.557 1.557 0 012.196 0l3.486 3.487a.853.853 0 101.205-1.208l-1.78-1.779a1.557 1.557 0 01.01-2.206 1.557 1.557 0 012.196 0l1.788 1.788c.004.002.002.002.002.002l.002.002.001.001.002.002h.002l.001.002a.852.852 0 001.19-1.224L36.265 23a1.545 1.545 0 01-.456-1.098c0-.415.16-.804.464-1.108.608-.604 1.59-.602 2.169-.029l6.386 7.02a7.66 7.66 0 011.995 5.159 7.613 7.613 0 01-2.245 5.42z" _fill="#F44336"/><path pid="2" d="M25.664 20.526A7.69 7.69 0 0018.676 16C14.445 16 11 19.444 11 23.677a7.695 7.695 0 004.526 6.989.857.857 0 001.129-.427.856.856 0 00-.427-1.13 5.982 5.982 0 01-3.522-5.433 5.977 5.977 0 015.97-5.97 5.982 5.982 0 015.434 3.523.853.853 0 101.554-.703z" _fill="#F44336"/>'
  }
})
