/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-sucess': {
    width: 23,
    height: 23,
    viewBox: '0 0 23 23',
    data: '<circle pid="0" opacity=".1" cx="11.5" cy="11.5" r="11.5" _fill="#42AE6F"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M18 7a1 1 0 01-.052 1.413l-6.974 6.475L7 10.914A1 1 0 018.414 9.5l2.612 2.611 5.56-5.163A1 1 0 0118 7z" _fill="#42AE6F"/>'
  }
})
