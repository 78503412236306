/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-acceuil': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<g filter="url(#filter0_d)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.924 1.643a.2.2 0 00-.248 0l-6 4.714a.2.2 0 00-.076.157v8.014c0 .11.09.2.2.2h12a.2.2 0 00.2-.2V6.514a.2.2 0 00-.076-.157l-6-4.714zM10.688.385a1.8 1.8 0 012.224 0l6 4.714a1.8 1.8 0 01.688 1.415v8.014a1.8 1.8 0 01-1.8 1.8h-12a1.8 1.8 0 01-1.8-1.8V6.514A1.8 1.8 0 014.688 5.1l6-4.714zM8.5 8.528a1.8 1.8 0 011.8-1.8h3a1.8 1.8 0 011.8 1.8v3a1.8 1.8 0 01-1.8 1.8h-3a1.8 1.8 0 01-1.8-1.8v-3zm1.8-.2a.2.2 0 00-.2.2v3c0 .11.09.2.2.2h3a.2.2 0 00.2-.2v-3a.2.2 0 00-.2-.2h-3z" _fill="#171D2D"/></g><defs><filter id="filter0_d" x="0" y="0" width="23.6" height="24.328" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter></defs>'
  }
})
