/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-camera': {
    width: 501,
    height: 401,
    viewBox: '0 0 501 401',
    data: '<path pid="0" d="M137.788 175.46c20.745 0 37.562-16.817 37.562-37.563 0-20.745-16.817-37.562-37.562-37.562-20.745 0-37.563 16.817-37.563 37.562 0 20.746 16.818 37.563 37.563 37.563zM212.913 250.582l-37.563-50.084-75.125 100.167h300.5l-112.687-150.25-75.125 100.167z" _fill="#000"/><path pid="1" d="M450.809.165H50.142C22.522.165.06 22.627.06 50.248v300.5c0 27.621 22.462 50.084 50.083 50.084H450.81c27.621 0 50.083-22.463 50.083-50.084v-300.5c0-27.62-22.462-50.083-50.083-50.083zM50.142 350.748v-300.5H450.81l.05 300.5H50.142z" _fill="#000"/>'
  }
})
