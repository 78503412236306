/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-infos': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<path pid="0" d="M5.778 10.111h1.444V5.778H5.778v4.333zM6.5 2.89c-.361 0-.722.361-.722.722s.36.722.722.722c.361 0 .722-.36.722-.722 0-.361-.36-.722-.722-.722zM6.5 0A6.473 6.473 0 000 6.5C0 10.111 2.889 13 6.5 13S13 10.111 13 6.5 10.111 0 6.5 0zm0 11.556A5.024 5.024 0 011.444 6.5 5.024 5.024 0 016.5 1.444 5.024 5.024 0 0111.556 6.5 5.024 5.024 0 016.5 11.556z" _fill="#CECECE"/>'
  }
})
