/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-action-use': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M12.776 9.415l-.577-1.04-1.242.69.577 1.038c.034.06.058.123.074.187a.704.704 0 01-.084.522.694.694 0 01-.61.347H7.645V9.74l-2.84 2.13L7.645 14v-1.42h3.267c.198 0 .392-.027.576-.078.355-.098.679-.288.937-.55a2.124 2.124 0 00.35-2.537zM1.866 6.216L.227 9.497a2.128 2.128 0 00.419 2.478c.127.124.267.232.423.322.324.185.691.283 1.064.283h1.253v-1.42H2.132a.692.692 0 01-.606-.34.71.71 0 01-.092-.507.759.759 0 01.063-.182l1.602-3.204 1.135.655-.424-3.524L.544 5.453l1.323.763zm9.045 1.367l.425-3.525-1.163.671-2.02-3.634A2.095 2.095 0 006.796.06a2.133 2.133 0 00-1.612.249c-.343.208-.62.51-.797.87l-.52 1.04 1.271.635.52-1.04a.752.752 0 01.118-.172.663.663 0 01.314-.192.748.748 0 01.377-.008.684.684 0 01.447.345l2.03 3.654-1.297.748 3.265 1.395z" _fill="#CECECE"/>'
  }
})
