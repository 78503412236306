/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic-see': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<circle pid="0" opacity=".1" cx="30" cy="30" r="30" _fill="#FFC107"/><path pid="1" d="M30 40.813a10.675 10.675 0 11.014-21.35A10.675 10.675 0 0130 40.813zm0-19.25a8.576 8.576 0 10.012 17.151A8.576 8.576 0 0030 21.562z" _fill="#FFC107"/><path pid="2" d="M42.747 19.843a23.064 23.064 0 00-25.495 0 22.083 22.083 0 00-8.172 9.892 1.053 1.053 0 000 .806 22.083 22.083 0 008.173 9.892 23.063 23.063 0 0025.495 0 22.081 22.081 0 008.172-9.892 1.052 1.052 0 000-.806 22.083 22.083 0 00-8.173-9.892zm-1.166 18.844a20.964 20.964 0 01-23.162 0 20.008 20.008 0 01-7.227-8.55 20.009 20.009 0 017.227-8.548 20.964 20.964 0 0123.162 0 20.012 20.012 0 017.226 8.549 20.011 20.011 0 01-7.227 8.549z" _fill="#FFC107"/><path pid="3" d="M26.752 26.89a4.593 4.593 0 106.495 6.495 4.593 4.593 0 00-6.495-6.495zm5.01 5.01a2.493 2.493 0 11-3.525-3.524 2.493 2.493 0 013.526 3.525z" _fill="#FFC107"/>'
  }
})
